import Image from 'next/image';

import Button from '@design0.2/Button/Button';
import { EVENT_TAG } from '@global/constants';
import { useLogFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import kemiAstronaut from '@static/media/kemi-astronaut.svg';
import { styled } from '@styles/stitches.config';

const InternalError = () => {
  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.GENERIC_ERROR.SCREEN_OPEN_GENERIC_ERROR
  );

  return (
    <Container>
      <ImageContainer>
        <Image src={kemiAstronaut} alt={''} />
      </ImageContainer>
      <Title>{i18n.t('k_hmm_something_went_wrong')}</Title>
      <SubTitle>{i18n.t('k_try_the_following_method')}</SubTitle>
      <Description>
        <List>{i18n.t('k_use_the_following_browser')}</List>
        <List>{i18n.t('k_refresh')}</List>
        <List>{i18n.t('k_in_case_of_mobile_use_pc')}</List>
      </Description>
      <Button
        buttonStyle={'primary'}
        onClick={() => navigator.clipboard.writeText(location.href)}
      >
        {i18n.t('k_link_copy')}
      </Button>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '16px 64px',
  textAlign: 'center',
  backgroundColor: '$primary10',
});

const ImageContainer = styled('div', {
  marginBottom: 24,
});

const Title = styled('span', {
  fontType: 'subtitleBold',
  marginBottom: 24,
});

const SubTitle = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 8,
});

const Description = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 24,
});

const List = styled('li', {
  fontType: 'bodyRegular',
  position: 'relative',
  paddingLeft: 12,

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: 3,
    height: 3,
    borderRadius: '50%',
    backgroundColor: '$black',
  },
});

export default InternalError;
