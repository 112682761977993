import Image from 'next/image';

import Button from '@design0.2/Button/Button';
import i18n from '@i18n/index';
import networkKemi from '@static/media/kemi-network.svg';
import { styled } from '@styles/stitches.config';

function NetworkError() {
  return (
    <Container>
      <ImageContainer>
        <Image src={networkKemi} alt={''} />
      </ImageContainer>
      <Title>{i18n.t('k_network_status_bad')}</Title>
      <SubTitle>{i18n.t('k_try_the_following_method')}</SubTitle>
      <Description>
        <List>{i18n.t('k_use_the_following_browser')}</List>
        <List>{i18n.t('k_check_mobile_data_or_wifi_connection_status')}</List>
        <List>{i18n.t('k_in_case_of_mobile_use_pc')}</List>
      </Description>
      <Button
        buttonStyle={'primary'}
        onClick={() => navigator.clipboard.writeText(location.href)}
      >
        {i18n.t('k_link_copy')}
      </Button>
      <LinkDescription
        target={'_blank'}
        rel={'noopener noreferer noreferrer'}
        href={'https://kemi.channel.io/lounge'}
      >
        {i18n.t('k_network_problem_is_not_solved')}
      </LinkDescription>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '16px 64px',
  textAlign: 'center',
  backgroundColor: '$primary10',
});

const ImageContainer = styled('div', {
  marginBottom: 24,
});

const Title = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 24,
});

const SubTitle = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 8,
});

const Description = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 24,
});

const List = styled('li', {
  fontType: 'bodyRegular',
  position: 'relative',
  paddingLeft: 12,

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: 3,
    height: 3,
    borderRadius: '50%',
    backgroundColor: '$black',
  },
});

const LinkDescription = styled('a', {
  fontType: 'captionRegular',
  color: '$grey100',
  marginTop: 24,
  cursor: 'pointer',
});

export default NetworkError;
