export const ROUTE_QUERY = {
  linkName: 'linkName',
  linkCardId: 'linkCardId',
  productId: 'productId',
  productCardId: 'productCardId',
  textCardId: 'textCardId',
  imageCardId: 'imageCardId',
  videoCardId: 'videoCardId',
  musicCardId: 'musicCardId',
  scheduleCardId: 'scheduleCardId',
  categoryName: 'categoryName',
};

const ROUTE = {
  index: '/',
  kemi: '/[linkName]',
  orders: {
    index: '/orders',
    detail: '/orders/[orderId]',
    checkout: {
      index: '/orders/[orderId]/checkout',
      success: '/orders/[orderId]/checkout/success',
    },
    cancelRequest: '/orders/[orderId]/cancel',
    cancelDetail: '/orders/[orderId]/cancel/detail',
    returnRequest: '/orders/[orderId]/return',
    returnDetail: '/orders/[orderId]/return/detail',
    exchangeRequest: '/orders/[orderId]/exchange',
    exchangeDetail: '/orders/[orderId]/exchange/detail',
    delivery: '/orders/[orderId]/deliveries/[orderLineItemId]',
    review: '/orders/[orderId]/review/[productCardId]',
  },
  products: {
    product: `/products/[${ROUTE_QUERY.productCardId}]`,
  },
  cart: {
    index: '/cart',
  },
  sellerCenter: {
    index: '/seller-center',
    orders: '/seller-center/orders',
    orderDetail: '/seller-center/orders/[orderId]',
    exchanges: '/seller-center/exchanges',
    returns: '/seller-center/returns',
    cancels: '/seller-center/cancels',
    products: {
      index: '/seller-center/product',
      list: '/seller-center/product/list',
      edit: '/seller-center/product/[productId]',
      new: '/seller-center/product/new',
      temporary: '/seller-center/product/temporary/[temporaryProductId]',
      editor: '/seller-center/product/editor',
    },
    settlement: {
      summary: '/seller-center/settlement/summary',
      history: '/seller-center/settlement/history',
      info: '/seller-center/settlement/info',
    },
    analytics: {
      index: '/seller-center/analytics',
      sales: {
        index: '/seller-center/analytics/sales',
        summary: '/seller-center/analytics/sales/summary',
        details: '/seller-center/analytics/sales/details',
        buyers: '/seller-center/analytics/sales/buyers',
        products: {
          product: {
            details: `/seller-center/analytics/sales/products/[${ROUTE_QUERY.productCardId}]/details`,
            buyers: `/seller-center/analytics/sales/products/[${ROUTE_QUERY.productCardId}]/buyers`,
          },
        },
      },
    },
    qna: 'https://kemi.oopy.io/94b3025e-a034-4196-9683-d01d35e5fd89',
  },
  mykemi: {
    index: '/mykemi',
    home: '/mykemi/home',
    social: '/mykemi/social',
    editTheme: '/mykemi/edit-theme',
    registerSeller: {
      index: '/mykemi/register-seller',
    },
    personalInfo: {
      index: '/mykemi/personal-info',
    },
    cardType: '/mykemi/card-type',
    links: {
      add: '/mykemi/links/add',
      edit: '/mykemi/links/edit',
    },
    products: {
      index: '/mykemi/products',
      list: '/mykemi/products/list',
      add: `/mykemi/products/[${ROUTE_QUERY.productId}]/add`,
      edit: `/mykemi/products/[${ROUTE_QUERY.productId}]/edit`,
      product: `/mykemi/products/[${ROUTE_QUERY.productId}]`,
      categories: {
        index: '/mykemi/products/categories',
        category: `/mykemi/products/categories/[${ROUTE_QUERY.categoryName}]`,
      },
    },
    scheduleCard: {
      add: '/mykemi/schedule-card/add',
      edit: '/mykemi/schedule-card/edit',
    },
    textCard: {
      add: '/mykemi/text-card/add',
      edit: '/mykemi/text-card/edit',
    },
    imageCard: {
      add: '/mykemi/image-card/add',
      edit: '/mykemi/image-card/edit',
    },
    videoCard: {
      add: '/mykemi/video-card/add',
      edit: '/mykemi/video-card/edit',
    },
    musicCard: {
      add: '/mykemi/music-card/add',
      edit: '/mykemi/music-card/edit',
    },
    analytics: {
      index: '/mykemi/analytics',
      kemi: {
        index: '/mykemi/analytics/kemi',
        summary: '/mykemi/analytics/kemi/summary',
        details: '/mykemi/analytics/kemi/details',
        visitors: '/mykemi/analytics/kemi/visitors',
      },
      sns: {
        index: '/mykemi/analytics/sns',
        summary: '/mykemi/analytics/sns/summary',
        details: '/mykemi/analytics/sns/details',
      },
    },
    settings: '/mykemi/settings',
  },
  pccc: '/pccc',
  terms: 'https://kemi.oopy.io/910c0088-3166-4371-92b7-18776af15ef2', // 이용약관
  privacy: 'https://kemi.oopy.io/e84a175b-38a4-41b3-9243-69d09c39e905 ', // 개인정보 처리방침
  privacyInfo: 'https://kemi.oopy.io/e335613c-c4b5-44c2-903f-fdd3d834a38d', // 개인정보 수집이용
  returnPolicy: 'https://kemi.oopy.io/c22fc4d3-fa16-470a-a2a1-9506fdcdfad7', // 교환/반품환불 정책
  cancelPolicy: 'https://kemi.oopy.io/e2d603ae-92cc-4e79-993f-40d7d5a168b9', // 주문취소 및 환불 규정
  businessInfo: 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6318701013', // 사업자 정보
  debugMode: '/debug-mode',
  oAuthCallback: '/oauth-callback/[provider]',
};

export default ROUTE;

export const SEO_ROUTES = [ROUTE.index, ROUTE.kemi, ROUTE.products.product];
