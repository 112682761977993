import * as Dialog from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import { PropsWithChildren, useState } from 'react';

import Card from '@design0.2/Cards/shared/components/Card';
import Icon from '@design0.2/Icon/Icon';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import useUserAgent from '@shared/hooks/useUserAgent';
import useRequestReviewModalStore from '@shared/store/useRequestReviewModalStore';
import { styled } from '@styles/stitches.config';

const RATING_NUMBER = [1, 2, 3, 4, 5];

export default function RequestReviewModal() {
  const router = useRouter();
  const [hoveredRate, setHoveredRate] = useState(0);
  const store = useRequestReviewModalStore();
  const review = store.requestReviewItems?.[0];

  const handleClickStar = (rating: number) => {
    store.actions.close();

    if (review) {
      router.push({
        pathname: ROUTE.orders.review,
        query: {
          orderId: review.orderId,
          productCardId: review.productCardId,
          rating,
        },
      });
    } else {
      router.push({
        pathname: ROUTE.orders.index,
      });
    }
  };

  return (
    <ModalContainer onClose={store.actions.close}>
      <Container>
        <ContentHeader>
          <CloseButton type={'button'} onClick={store.actions.close}>
            <Icon type={'close | black'} />
          </CloseButton>
        </ContentHeader>
        <Content>
          <Card
            thumbnailType={'IMAGE'}
            showImage
            imageUrl={review?.imageUrls?.[0] || ''}
            title={
              <CardTitle>
                <ProductTitle>{review?.productTitle || ''}</ProductTitle>
                <OptionDescription>
                  {review?.productOptionTitles?.join(', ')}
                </OptionDescription>
              </CardTitle>
            }
          />
          <RatingContainer>
            {i18n.t('k_request_modal_description') + '  🤩'}
            <Stars>
              {RATING_NUMBER.map((rate) => (
                <Icon
                  onClick={() => handleClickStar(rate)}
                  onTouchStart={() => setHoveredRate(rate)}
                  onMouseOver={() => setHoveredRate(rate)}
                  type={
                    hoveredRate >= rate
                      ? 'favorite | large | filled'
                      : 'favorite | large | black'
                  }
                />
              ))}
            </Stars>
          </RatingContainer>
        </Content>
      </Container>
    </ModalContainer>
  );
}

const ModalContainer = ({
  children,
  onClose,
}: PropsWithChildren<{ onClose: () => void }>) => {
  const { isDesktop } = useUserAgent();
  return (
    <Dialog.Root defaultOpen={true} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent
            // radix에서 해주는 focus로 인한 outline 효과를 없애기 위한 로직
            onOpenAutoFocus={(e) => e.preventDefault()}
            isDesktop={isDesktop}
          >
            {children}
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const Container = styled('div', {
  margin: 16,
  borderRadius: 16,
  transparentColor: 'translucentGrey',
});

const DialogOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: '$modal',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const DialogContent = styled(Dialog.Content, {
  width: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  height: '100vh',
  justifyContent: 'flex-end',
  variants: {
    isDesktop: {
      true: {
        maxWidth: 480,
        width: '$appWidthWhenDesktop',
      },
    },
  },
});

const CloseButton = styled('button', {
  cursor: 'pointer',
});

const ContentHeader = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: 16,
  gap: 16,
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 16,
  justifyContent: 'center',
});

const RatingContainer = styled('div', {
  display: 'flex',
  padding: 16,
  gap: 8,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  fontType: 'subtitleBold',
  '& > .star:hover': {
    color: 'red',
  },
});

const Stars = styled('div', {
  display: 'flex',
  padding: 16,
  gap: 8,
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
});

const CardTitle = styled('div', {
  gridRowWithGap: 2,
});

const ProductTitle = styled('div', {
  fontType: 'bodyRegular',
  ellipse: 1,
});

const OptionDescription = styled('div', {
  fontType: 'captionRegular',
  color: '$grey100',
  ellipse: 1,
});
