import { isNil } from 'lodash';
import Link from 'next/link';
import { forwardRef, HTMLProps, useMemo } from 'react';

import SellerCenterDashboardSkeleton from './SellerCenterDashboardSkeleton';

import Text from '@global/components/atoms/Text';
import { EVENT_TAG } from '@global/constants';
import { User } from '@global/queries/useUserQuery';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { useGetFacebookAuth } from '@pages/app/hooks/useGetFacebookAuth';
import { useKemiVisitorsSummary } from '@pages/mykemi/analytics/kemi/summary/source/hooks/useKemiVisitorsSummary';
import useInstagramAnalyticsSummary from '@pages/mykemi/analytics/sns/summary/source/hooks/useInstagramAnalyticsSummary';
import ROUTE from '@routers';
import useSellerCenterDashboardData from '@seller-center/shared/hooks/useSellerCenterDashboardData';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';
import { getRateOfChange } from '@utils/number';
import { addComma } from '@utils/string';

const formatRate = (rate?: number) => {
  return !isNil(rate)
    ? rate > 0
      ? `+${addComma(Math.floor(rate))}%`
      : `${addComma(Math.floor(rate))}%`
    : '';
};

export const KemiDashboard = () => {
  const { hasFacebookPermission } = useGetFacebookAuth();
  const { todayVisitors, yesterdayVisitors } = useKemiVisitorsSummary();
  const visitorPercentage = getRateOfChange(yesterdayVisitors, todayVisitors);
  const { summaryData } = useInstagramAnalyticsSummary();
  const todayFollowersCount = summaryData?.todayFollowersCount || 0;
  const yesterdayFollowersCount = summaryData?.yesterdayFollowersCount || 0;
  const followerPercentage = getRateOfChange(
    yesterdayFollowersCount,
    todayFollowersCount
  );

  return (
    <>
      <Link href={ROUTE.mykemi.analytics.kemi.index} passHref>
        <Content
          title={i18n.t('k_today_s_kemi_visitor')}
          count={todayVisitors}
          bottom={<GrowSign>{formatRate(visitorPercentage)}</GrowSign>}
          onClick={() => {
            logFirebase(
              UserInteractionType.CLICK,
              EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_BLOCK_VISITOR_CLICK
            );
          }}
        />
      </Link>
      {hasFacebookPermission && (
        <Link href={ROUTE.mykemi.analytics.sns.index} passHref>
          <Content
            title={i18n.t('k_instagram_followers')}
            count={todayFollowersCount}
            bottom={<GrowSign>{formatRate(followerPercentage)}</GrowSign>}
          />
        </Link>
      )}
    </>
  );
};

type SellerCenterDashboardProps = {
  user: User;
};

export const SellerCenterDashboard = ({ user }: SellerCenterDashboardProps) => {
  const isSeller = user?.sellerInfo?.isSeller;
  // seller 가 아닐 경우 api를 호출하지 않는다.
  // dashboard는 Errorboundary 에 속하지 않으므로 enabled 옵션을 사용해야한다.
  const { isLoading, data } = useSellerCenterDashboardData({
    enabled: false && !!isSeller,
  });
  const { isDesktop } = useUserAgent();
  const { exchangeUrl, returnUrl } = useMemo(
    () =>
      isDesktop
        ? {
            exchangeUrl: ROUTE.sellerCenter.exchanges,
            returnUrl: ROUTE.sellerCenter.returns,
          }
        : {
            exchangeUrl: ROUTE.sellerCenter.orders,
            returnUrl: ROUTE.sellerCenter.orders,
          },
    [isDesktop]
  );

  return null;

  if (isLoading) {
    return <SellerCenterDashboardSkeleton isSeller={isSeller} />;
  }

  if (isSeller) {
    return (
      <>
        <Link href={ROUTE.sellerCenter.orders} passHref>
          <Content
            title={`🛎 ${i18n.t('k_new_order')}`}
            count={data.new}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_NEWORDER_CLICK
              );
            }}
          />
        </Link>
        <Link href={ROUTE.sellerCenter.analytics.sales.index} passHref>
          <Content
            title={`💰 ${i18n.t('k_todays_sales')}`}
            count={data.sales.amount}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_NEWORDER_CLICK
              );
            }}
            bottom={
              <Text font={'labelRegular'}>{formatRate(data.sales.growth)}</Text>
            }
          />
        </Link>
        <Link href={exchangeUrl} passHref>
          <Content
            title={'🚨 ' + i18n.t('k_exchange_request_2')}
            count={data.exchange}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_EXCHANGEREQ_CLICK
              );
            }}
          />
        </Link>
        <Link href={returnUrl} passHref>
          <Content
            title={'😭 ' + i18n.t('k_return_request_2')}
            count={data.return}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_REFUNDREQ_CLICK
              );
            }}
          />
        </Link>
      </>
    );
  }

  return (
    <>
      <Link href={exchangeUrl} passHref>
        <Content title={'🚨 ' + i18n.t('k_exchange_request_2')} count={5} />
      </Link>
      <Link href={returnUrl} passHref>
        <Content title={'😭 ' + i18n.t('k_return_request_2')} count={1} />
      </Link>
      <Link href={ROUTE.sellerCenter.analytics.sales.index} passHref>
        <Content
          title={`❤️ ${i18n.t('k_new_order')}`}
          count={381}
          bottom={<GrowSign>{'+25%'}</GrowSign>}
        />
      </Link>
    </>
  );
};

type ContentProps = {
  title?: string;
  count?: number;
  bottom?: JSX.Element | string;
} & HTMLProps<HTMLAnchorElement>;

const Content = forwardRef<HTMLAnchorElement, ContentProps>(
  ({ href, onClick, title, count, bottom }: ContentProps, ref) => {
    return (
      <ContentContainer href={href} ref={ref} onClick={onClick}>
        <Text font={'captionRegular'} color={'black'} className={'title'}>
          {title}
        </Text>
        <Text font={'bodyBold'} color={'black'} className={'count'}>
          {addComma(count || 0)}
        </Text>
        {bottom}
      </ContentContainer>
    );
  }
);
Content.displayName = 'menu-dashboard-item';

export const DashboardContainer = styled('div', {
  display: 'inline-flex',
  flexWrap: 'wrap',
  margin: '5px',
  verticalAlign: 'top',
  width: '100%',
  gap: 8,
  '& .title': {
    marginBottom: 8,
  },
  '& .count': {
    marginBottom: 4,
  },
});

const ContentContainer = styled('a', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 16,
  background: '$purple10',
  paddingY: 24,
  cursor: 'pointer',
});

const GrowSign = styled('div', {
  fontType: 'labelRegular',
});
