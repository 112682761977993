import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React from 'react';

import i18n from '@i18n/index';

const KemiHead = () => {
  const router = useRouter();
  return (
    <DefaultSeo
      title={i18n.t('k_kemi_smart_profiles_to_help_monetize_kemi')}
      description={i18n.t(
        'k_kemi_create_an_sns_profile_kemi_that_monetizes_my_influence_for_free'
      )}
      openGraph={{
        title: i18n.t('k_kemi_smart_profiles_to_help_monetize_kemi'),
        type: 'website',
        description: i18n.t(
          'k_kemi_create_an_sns_profile_kemi_that_monetizes_my_influence_for_free'
        ),
        url: `${process.env.NEXT_PUBLIC_HOST_NAME}${router.asPath}`,
        images: [
          {
            url: 'https://asset.kemist.io/og/landing.png',
            alt: i18n.t('k_seo_image_alt_text'),
          },
        ],
      }}
    />
  );
};

export default KemiHead;
