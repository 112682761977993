import create from 'zustand';
import { devtools } from 'zustand/middleware';

import i18n from '@i18n/index';

type ConfirmModalValues = {
  isOpen: boolean;
  title?: string;
  description?: string | JSX.Element;
  confirmText?: string;
  cancelText?: string;
  actions?: {
    confirm?: () => void;
    cancel?: () => void;
    backgroundClick?: () => void;
  };
};

type ConfirmModalActions = {
  open: (values: Omit<ConfirmModalValues, 'isOpen'>) => void;
  close: () => void;
};

type ConfirmModalStore = ConfirmModalValues & ConfirmModalActions;

const initialValue: ConfirmModalValues = {
  isOpen: false,
  title: '',
  description: '',
  confirmText: i18n.t('k_confirm'),
  cancelText: i18n.t('k_cancel'),
  actions: {
    confirm: async () => {},
    cancel: undefined,
  },
};

const useConfirmModalStore = create<ConfirmModalStore>()(
  devtools(
    (set) => ({
      ...initialValue,
      open: (values) => {
        set(() => ({ ...values, isOpen: true }));
      },
      close: async () => {
        set(() => ({ ...initialValue }));
      },
    }),
    { name: 'confirmModal' }
  )
);

export default useConfirmModalStore;
